<template>
  <b-card>
    <ConfirmModal
      ref="confirmModal"
      variant="danger"
      :title="confirmModal.title"
      :description="confirmModal.description"
    />
    <b-tabs
      v-model="currentTab"
      justified
      pills>
      <!-- Render Tabs, supply a unique `key` to each tab -->
      <b-tab
        v-for="(ticket, index) in tickets"
        :key="'dyn-tab-' + index"
        title-link-class="rounded-tab"
        :title="'Ticket' + index">
        <template #title>
          <div
            class="d-flex align-items-center justify-content-between"
            style="height: 30px">
            <span class="d-inline-block"></span>
            <span
              v-if="ticket && ticket.code"
              class="d-inline-block"
              style="color: black;">{{ ticket.code }}
            </span>
            <span
              v-else
              class="d-inline-block">
              Ticket
            </span>
            <span
              class="d-inline-block"
              @click="closeTab(index)">
              <b style="font-size: 20px;">X</b>
            </span>
          </div>
        </template>
        <TicketTab
          v-if="isLoaded"
          :tab-index="index"
          :item="ticket"
          :all-ref="allRef"
          @switch-tab="changeCurrentTab($event)"
        />
      </b-tab>

      <!-- New Tab Button (Using tabs-end slot) -->
      <template
        v-if="tickets.length < 5"
        #tabs-end>
        <b-nav-item
          role="presentation"
          @click.prevent="newTab">
          <b style="font-size: 26px;">+</b>
        </b-nav-item>
      </template>

      <!-- Render this if no tabs -->
      <template>
        <div class="text-center text-muted">
        </div>
      </template>
    </b-tabs>

    <template>
    </template>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TicketProvider from '@/resources/TicketProvider'
import ConfirmModal from '@/components/ConfirmModal.vue'
import returnDataTicket from '@/utils/data-ticket'

import ContactChannleProvider from '@/resources/ContactChannelProvider'
// import CallCategoryProvider from '@/resources/CallCategoryProvider'
import CategoriesProvider from '@/resources/CategoriesProvider'
import TicketTypeProvider from '@/resources/TicketType'
import PlantProvider from '@/resources/PlantProvider'
import CustomerGroupProvider from '@/resources/CustomerGroupProvider'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'
import TicketTab from './TicketTab.vue'

const CustomerGroupService = new CustomerGroupProvider()
const CustomerTypeService = new CustomerTypeProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()
const CompanyCenterService = new CompanyCenterProvider()

const TicketService = new TicketProvider()
const ContactChannelService = new ContactChannleProvider()
// const CallCategoryServie = new CallCategoryProvider()
const CategoriesService = new CategoriesProvider()
const TicketTypeService = new TicketTypeProvider()
const PlantService = new PlantProvider()

export default {
  components: {
    TicketTab,
    ConfirmModal
  },
  data () {
    return {
      isLoaded: true,
      currentTab: 0,
      tabs: [1],
      tabCounter: 1,
      confirmModal: {
        title: 'Are you sure ?',
        description: 'You want to close tabs ?'
      },
      allRef: {
        ticketType: [],
        contactChannel: [],
        callCategory: [],
        categories: [],
        plant: [],
        customerType: [],
        customerGroup: [],
        companyGroup: [],
        companyDepartment: [],
        companyCenter: []
      }
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      tickets: 'ticket/tickets',
      profile: 'User/user'
    })
  },
  watch: {
    tickets (newTicket) {
      if (newTicket) {
        const ticketLength = newTicket?.length - 1
        setTimeout(() => {
          this.changeCurrentTab(ticketLength)
        }, 100)
      }
    }
  },
  async mounted () {
    try {
      const { tickets } = this
      if (Array.isArray(tickets) && tickets.length > 0) {
        const ticketLength = tickets.length - 1
        setTimeout(() => {
          this.changeCurrentTab(ticketLength)
        }, 300)
      }
      await this.getAllOptions()
      await this.getAllRefCustomer()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    ...mapActions({
      addTicket: 'ticket/ADD_TICKET',
      deleteTicket: 'ticket/DELETE_TICKET'
    }),
    async closeTab (x) {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to close tab ?' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          this.isLoaded = false
          this.deleteTicket(x)
          setTimeout(() => {
            this.isLoaded = true
          }, 100)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async newTab () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await TicketService.createInitial(this.profile.id)
        const payload = returnDataTicket(data)
        const addTicket = await this.addTicket(payload)
        if (Array.isArray(addTicket) && addTicket.length > 0) {
          const numIndex = addTicket.length - 1
          setTimeout(() => {
            this.changeCurrentTab(numIndex)
          }, 300)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getAllOptions () {
      try {
        this.isShowComponent = false
        const categories = await CategoriesService.findAll()
        this.allRef.categories = categories?.data
        const ticketType = await TicketTypeService.findAllType()
        this.allRef.ticketType = ticketType?.data
        const contactChannel = await ContactChannelService.findAllChannel()
        this.allRef.contactChannel = contactChannel?.data
        // const callCategory = await CallCategoryServie.findAllCallCategory()
        const plant = await PlantService.findAllPlant()
        this.allRef.plant = plant?.data?.map((item) => ({ ...item, code: `${item?.code} - ${item?.name}` }))
        // this.allRef.callCategory = callCategory.data
      } catch (error) {
        console.error(error)
      } finally {
        this.isShowComponent = true
      }
    },
    async getAllRefCustomer () {
      try {
        const customerGroup = await CustomerGroupService.findAllGroup()
        const customerType = await CustomerTypeService.findAllType()
        const companyGroup = await CompanyGroupService.findAllGroup()
        const companyDepartment = await CompanyDepartmentService.findAllCompanyDepartment()
        const companyCenter = await CompanyCenterService.findAllCompanyCenter()
        this.allRef.customerType = customerType.data
        this.allRef.customerGroup = customerGroup.data
        this.allRef.companyGroup = companyGroup.data
        this.allRef.companyDepartment = companyDepartment.data
        this.allRef.companyCenter = companyCenter.data
      } catch (error) {
        console.error(error)
      }
    },
    changeCurrentTab (index) {
      this.currentTab = index
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
