<template>
  <b-modal
    id="modal-danger"
    ref="confirm"
    v-model="isVisible"
    v-bind="$attrs"
    :ok-variant="variant"
    ok-title="Accept"
    modal-class="modal-normal"
    centered
    v-on="{ ...$listeners }">
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        {{ title }}
      </p>
    </div>
    <div
      class="mx-auto mb-2"
      style="width: 80%;">
      {{ description }}
    </div>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          :variant="variant"
          style="margin-right: 12px"
          @click="_confirm()">
          Confirm
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    // variant: {
    //   type: String,
    //   default: 'primary'
    // }
    // title: {
    //   type: String,
    //   default: 'Title'
    // },
    // description: {
    //   type: String,
    //   default: 'Description'
    // }
  },
  data () {
    return {
      title: 'Are you sure?',
      description: '',
      variant: 'danger',
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null
    }
  },
  methods: {
    show ({ title, description, variant }) {
      this.title = title || 'Are you sure?'
      this.description = description
      this.variant = variant || 'danger'
      this.isVisible = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm () {
      this.isVisible = false
      this.resolvePromise(true)
    },

    _cancel () {
      this.isVisible = false
      this.resolvePromise(false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
