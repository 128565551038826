import HttpRequest from './HttpRequest'

class MicrosoftProvider extends HttpRequest {
  /**
   * Assign the project to an employee.
   * @param {Object} payload
   * @param {string} payload.subject
   * @param {Object} payload.html
   * @param {Object} payload.to
   * @param {Object} payload.cc
   */
  sendMail (payload) {
    return this.post('/microsoft/messages', payload)
  }

  getMessageByTicketId (ticketId) {
    return this.get(`/microsoft/messages/${ticketId}/ticket`)
  }
}

export default MicrosoftProvider
