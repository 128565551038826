import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class TicketPlantProvider extends HttpRequest {
  findAll () {
    return this.get('/plant')
  }

  findAllPlant () {
    return this.get('/plant/all/plant')
  }

  createGroup (payload) {
    return this.post('/plant', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/plant/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/plant/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/plant?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default TicketPlantProvider
