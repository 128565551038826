<template>
  <div>
    <CustomerAddContact
      ref="customerContactAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-customer-contact="addCustomerContact($event)"
    />
    <b-card
      class="p-2"
      @hidden="resetForm()">
      <template>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="CustomerAddForm">
          <!-- Form -->

          <b-form
            ref="ResetForm"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <div>
              <h4>
                Search Customer
              </h4>
              <b-row>
                <b-col
                  md="4"
                  cols="12"
                >
                  <SearchTextInput
                    v-model="ani"
                    name="Ani"
                    label="Search By Phone No."
                    placeholder="Search By Phone No."
                    trim
                    @keydown.enter="searchCustomerAndContact(ani)"
                  />
                </b-col>
                <b-col
                  md="4"
                  cols="12"
                >
                  <SearchTextInput
                    v-model="customerName"
                    name="Customer Name"
                    label="Search By Customer Code or Name"
                    placeholder="Search by Customer Code or Name"
                    trim
                    @keydown.enter="searchCustomerAndContact(ani)"
                  />
                </b-col>
                <b-col
                  md="4"
                  cols="12"
                >
                  <SearchTextInput
                    v-model="contactName"
                    name="Contact Name"
                    label="Search By Contact Name"
                    placeholder="Search by Contact Name"
                    trim
                    @keydown.enter="searchCustomerAndContact(ani)"
                  />
                  <!-- <AutoCompleteField
                    v-model="customerName"
                    name="Contact Name"
                    label="Search By Contact Name"
                    placeholder="Search By Contact Name"
                    item-value="name"
                    :filter-items="filterCustomer"
                    :items="customerProfileItems"
                    @keydown="searchCustomerProfileByName()"
                    @select="selectCustomer($event)"
                  >
                    <template #item-text="{ item }">
                      {{ item.name }}
                    </template>
                  </AutoCompleteField> -->
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-button
                      class="mr-2"
                      variant="primary"
                      @click="searchCustomerAndContact(ani)">
                      Search
                    </b-button>
                    <!-- <b-button
                      variant="primary"
                      @click="resetForm()">
                      Clear
                    </b-button> -->
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div
              v-if="customerProfileAndContact && customerProfileAndContact.length > 0"
              class="mt-2 mb-4">
              <h4>
                Customer List
              </h4>
              <b-table
                v-model="currentItems"
                striped
                hover
                responsive
                :items="customerProfileAndContact"
                :fields="fields"
                @row-clicked="rowProfileClick">
                >
                <template #cell(showContact)="row">
                  <div
                  >
                    <feather-icon
                      v-if="row.detailsShowing"
                      icon="ChevronDownIcon"
                      size="18" />
                    <feather-icon
                      v-else
                      icon="ChevronRightIcon"
                      size="18" />
                  </div>
                </template>
                <template #row-details="{ item }">
                  <div v-if="item.customerContacts.length > 0">
                    <b-table
                      striped
                      hover
                      responsive
                      :fields="fieldsTest"
                      :items="item.customerContacts"
                    >
                      <template #cell(name)="contact">
                        {{ contact.item.firstName }} {{ contact.item.lastName }}
                      </template>
                      <template #cell(action)="contact">
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="selectContactOnly(item, contact.item)"
                        >
                          Select
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </template>
                <template #cell(customerName)="{item}">
                  <div v-if="item.name">
                    {{ item.name }}
                  </div>
                  <div v-else-if="item.customerProfile && item.customerProfile.name">
                    {{ item.customerProfile.name }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(contactName)="{ item }">
                  <div v-if="item.firstName || item.lastName">
                    <span v-if="item.firstName">
                      {{ item.firstName }}
                    </span>
                    <span v-if="item.lastName">
                      {{ item.lastName }}
                    </span>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(phoneNumber)="{ item }">
                  <div v-if="item.ani">
                    {{ item.ani }}
                  </div>
                  <div v-else-if="item.companyOfficePhone">
                    {{ item.companyOfficePhone }}
                  </div>
                  <div v-else-if="item.companyMobilePhone">
                    {{ item.companyMobilePhone }}
                  </div>
                  <div v-else-if="item.companyMobilePhone">
                    {{ item.companyMobilePhone }}
                  </div>
                  <div v-else-if="item.contactPhoneNo">
                    {{ item.contactPhoneNo }}
                  </div>
                  <div v-else-if="item.contactPhoneNo2">
                    {{ item.contactPhoneNo2 }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(lineId)="{ item }">
                  <div v-if="item.contactLine">
                    {{ item.contactLine }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(facebookId)="{ item }">
                  <div v-if="item.contactFacebook">
                    {{ item.contactFacebook }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(emailAccount)="{ item }">
                  <div v-if="item.companyEmail">
                    {{ item.companyEmail }}
                  </div>
                  <div v-else-if="item.contactEmail">
                    {{ item.contactEmail }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(action)="{ item }">
                  <b-button
                    v-if="(customerProfile && item.customerProfile && item.customerProfile.id === customerProfile.id && ticket.contactName === `${item.firstName} ${item.lastName}`)"
                    size="sm"
                    variant="danger"
                    @click="clearCustomerAndContact()"
                  >
                    Selected
                  </b-button>
                  <b-button
                    v-else-if="(customerProfile && !item.customerProfile && item.id === customerProfile.id)"
                    size="sm"
                    variant="danger"
                    @click="clearCustomerAndContact()"
                  >
                    Selected
                  </b-button>
                  <b-button
                    v-else-if="(ticket && item.id === ticket.customerProfileId)"
                    size="sm"
                    variant="danger"
                    @click="clearCustomerAndContact()"
                  >
                    Selected
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    variant="primary"
                    @click="selectCustomerOnly(item)"
                  >
                    Select
                  </b-button>
                </template>
                <template #cell(addContact)="{ item }">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="selectAddContact(item)"
                  >
                    Add Contact
                  </b-button>
                </template>
              </b-table>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="pagination.page"
                  :total-rows="pagination.totalDocs"
                  :per-page="pagination.limit"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="showCurrentPage($event)"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </div>
          </b-form>
        </ValidationObserver>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="CustomerAddForm">
          <b-form
            ref="customerForm"
            @submit.prevent="handleSubmit(createCustomerProfileAndContact)"
          >
            <div class="mt-2">
              <h4>
                <feather-icon
                  size="30"
                  icon="UserIcon"
                />
                Add New Customer
              </h4>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.customerId"
                    :rules="{ required: false }"
                    name="Customer Code"
                    label="Customer Code"
                    placeholder="Input Customer Code"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.name"
                    :rules="{ required: true }"
                    name="Customer Name"
                    label="Customer Name"
                    placeholder="Input Customer Name"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="form.customer.customerTypeId"
                    :rules="{ required: true }"
                    name="Customer Type"
                    form-label="Customer Type"
                    placeholder="Choose Customer Type"
                    label-option="name"
                    return-value="id"
                    :options="customerType" />
                </b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="form.customer.customerGroupId"
                    :rules="{ required: false }"
                    name="Customer Group"
                    form-label="Customer Group"
                    placeholder="Choose Customer Name"
                    label-option="name"
                    return-value="id"
                    :options="customerGroup" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.sapSoldToCode"
                    name="SAP Sold To Code"
                    label="SAP Sold To Code"
                    placeholder="Input Customer Name"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.sapShipToCode"
                    name="SAP Ship Code"
                    label="SAP Ship Code"
                    placeholder="Input SAP Ship Code"
                    trim />
                </b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.refCode1"
                    name="Ref Code 1"
                    label="Ref Code 1"
                    placeholder="Input Ref Code 1"
                    trim />
                </b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="8">
                  <TextFieldInput
                    v-model="form.customer.companyAddress"
                    name="Company Address"
                    label="Company Address"
                    placeholder="Input Company Address"
                    trim />
                </b-col>
                <b-col></b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <AddressField
                    v-model="form.customer.companySubDistrict"
                    label="Company SubDistrict"
                    type="sub-district"
                    @select="onSelectAddress($event)" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <AddressField
                    v-model="form.customer.companyDistrict"
                    label="Company District"
                    type="district"
                    @select="onSelectAddress($event)" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <AddressField
                    v-model="form.customer.companyProvince"
                    label="Company Province"
                    type="province"
                    @select="onSelectAddress($event)" />
                </b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <AddressField
                    v-model="form.customer.companyZipCode"
                    label="Company ZipCode"
                    type="postcode"
                    @select="onSelectAddress($event)" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.companyEmail"
                    :rules="{ email: true }"
                    name="Company Email"
                    label="Company Email"
                    placeholder="Input Company Email"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.companyOfficePhone"
                    :rules="{ required: false }"
                    name="Company Office Phone"
                    label="Company Office Phone"
                    placeholder="Input Company Office Phone"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.customer.companyMobilePhone"
                    :rules="{ required: false }"
                    name="Company Mobile Phone"
                    label="Company Mobile Phone"
                    placeholder="Input Company Mobile Phone"
                    trim />
                </b-col>
              </b-row>
            </div>
            <div class="mt-2">
              <h4>
                <feather-icon
                  size="30"
                  icon="UsersIcon"
                />
                Add New Contact
              </h4>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.firstName"
                    :rules="{ required: false }"
                    name="First Name"
                    label="First Name"
                    placeholder="First Name"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.lastName"
                    :rules="{ required: false }"
                    name="Last Name"
                    label="Last Name"
                    placeholder="Last Name"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.contactPhoneNo"
                    :rules="{ required: false }"
                    name="Phone No."
                    label="Phone No."
                    placeholder="Phone No"
                    trim />
                </b-col>
              </b-row>
              <b-row class="align-items-end">
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.contactLine"
                    name="Line Account ID"
                    label="Line Account ID"
                    placeholder="Line Account ID"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.contactFacebook"
                    name="Facebook Account ID"
                    label="Facebook Account ID"
                    placeholder="Facebook Account ID"
                    trim />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <TextFieldInput
                    v-model="form.contact.contactEmail"
                    name="Email Address"
                    label="Email Address"
                    placeholder="Email Address"
                    trim />
                </b-col>
              </b-row>
              <div class="mt-4">
                <b-button
                  class="mr-2"
                  variant="primary"
                  type="submit"
                >
                  <span class="align-middle">Add New</span>
                </b-button>
                <b-button
                  variant="outline-secondary"
                >
                  <span class="align-middle">Cancel</span>
                </b-button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import AddressField from '@/components/Form/AddressField.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import AutoCompleteField from '@/components/Form/AutoCompleteField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import CustomerAddContact from '@/views/Customers/Components/CustomerAddContact.vue'

import CustomerProvider from '@/resources/CustomerProvider'
import CustomerGroupProvider from '@/resources/CustomerGroupProvider'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'
import CustomerContactProvider from '@/resources/CustomerContactProvider'

const CustomerService = new CustomerProvider()
const CustomerGroupService = new CustomerGroupProvider()
const CustomerTypeService = new CustomerTypeProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()
const CompanyCenterService = new CompanyCenterProvider()
const CustomerContactService = new CustomerContactProvider()

export default {
  components: {
    // AutoCompleteField,
    TextFieldInput,
    SelectField,
    AddressField,
    CustomerAddContact,
    SearchTextInput
  },
  props: {
    ticket: {
      type: Object,
      require: true
    },
    customerProfile: {
      type: Object,
      default: () => ({
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      })
    },
    customerAni: {
      type: String,
      default: ''
    },
    customerItems: {
      type: Array,
      default: () => []
    },
    allRef: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      currentItems: [],
      isSidebarActive: false,
      isEditModalActive: false,
      isDeleteModalActive: false,
      isConfirmEditModalActive: false,
      isConfirmDeleteModalActive: false,
      currentAddContact: null,
      currentPhoneNumber: null,
      customerName: '',
      customerId: null,
      ani: '',
      editItem: {},
      statusCheckbox: '',
      items: [
        // { text: 'Puncharas', value: 'customerName' },
        // { text: 'Hoon', value: 'contactName' }
      ],
      fields: [
        { key: 'showContact', label: '' },
        { key: 'customerName', label: 'Customer Name', sortable: false },
        { key: 'phoneNumber', label: 'Phone No.', sortable: false },
        { key: 'action', label: '', sortable: false },
        { key: 'addContact', label: '', sortable: false }
      ],
      fieldsTest: [
        { key: 'name', label: 'Contact Name', sortable: false },
        { key: 'contactPhoneNo', label: 'Phone No.', sortable: false },
        { key: 'contactLine', label: 'LINE ID', sortable: false },
        { key: 'contactFacebook', label: 'Facebook ID', sortable: false },
        { key: 'contactEmail', label: 'Email Account', sortable: false },
        { key: 'action', label: '', sortable: false },
        { key: 'addContact', label: '', sortable: false }
      ],
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      form: {
        customer: {
          customerId: '',
          name: '',
          ani: '',
          customerTypeId: '',
          customerGroupId: null,
          sapSoldToCode: '',
          sapShipToCode: '',
          refCode1: '',
          refCode2: '',
          refCode3: '',
          companyAddress: '',
          companySubDistrict: '',
          companyDistrict: '',
          companyProvince: '',
          companyZipCode: '',
          companyOfficePhone: '',
          companyMobilePhone: '',
          companyEmail: '',
          companyGroupId: null,
          companyDepartmentId: null,
          companyCenterId: null,
          remark1: '',
          remark2: '',
          remark3: '',
          queueAgent: 1
        },
        contact: {
          firstName: '',
          lastName: '',
          contactPhoneNo: '',
          contactEmail: '',
          contactLine: '',
          contactFacebook: ''
        }
      },
      customerGroup: [],
      customerType: [],
      companyGroup: [],
      companyDepartment: [],
      companyCenter: [],
      customerProfileAndContact: [],
      contactName: ''
    }
  },
  computed: {
    customerProfileItems: {
      get () {
        return this.customerItems
      },

      set (val) {
        this.$emit('update:customer-items', val)
      }
    },

    customer: {
      get () {
        return this.customerProfile
      },
      set (val) {
        this.$emit('update:customer-profile', val)
      }
    }
  },
  mounted () {
    this.getAll()
    if (this.customerAni) {
      this.searchCustomerAndContact(this.customerAni)
      this.ani = this.customerAni
      // this.ani = this.customerAni
      // this.searchCustomer()
    }
  },
  methods: {
    async createCustomerProfileAndContact () {
      try {
        // const payload = { ...this.customer }
        // payload.ani = this.customer.companyOfficePhone
        // payload.queueAgent = 1
        const payload = {
          ...this.form
        }
        payload.customer.ani = this.$replacePhoneNumber(payload.customer.ani, '+66')
        payload.customer.companyOfficePhone = this.$replacePhoneNumber(payload.customer.companyOfficePhone, '+66')
        payload.customer.companyMobilePhone = this.$replacePhoneNumber(payload.customer.companyMobilePhone, '+66')
        payload.contact.contactPhoneNo = this.$replacePhoneNumber(payload.contact.contactPhoneNo, '+66')
        const { data } = await CustomerService.createCustomerAndContact(payload)
        this.selectCustomerOnly(data)
        this.ani = data?.ani || data?.companyMobilePhone || data?.companyOfficePhone
        if (this.form?.customer?.name) {
          this.customerName = this.form?.customer?.name
        }
        if (this.form?.contact?.firstName || this.form?.contact?.lastName) {
          this.contactName = `${this.form?.contact?.firstName} ${this.form?.contact?.lastName}`
        }
        this.ani = this.$replacePhoneNumber(this.ani, '+66')
        this.searchCustomerAndContact(this.ani)
        this.resetCustomerForm()
        this.$refs.CustomerAddForm.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Profile has been added'
          }
        })
        // this.$router.push(`/customer/detail/${data.id}`)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Record Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `👋 Customer Profile has been failed ${error}`
          }
        })
      }
    },
    async getAll () {
      try {
        const { allRef } = this
        if (allRef?.customerGroup) {
          this.customerGroup = allRef?.customerGroup
        } else {
          const customerGroup = await CustomerGroupService.findAllGroup()
          this.customerGroup = customerGroup.data
        }

        if (allRef?.customerType) {
          this.customerType = allRef?.customerType
        } else {
          const customerType = await CustomerTypeService.findAllType()
          this.customerType = customerType.data
        }

        if (allRef?.companyGroup) {
          this.companyGroup = allRef?.companyGroup
        } else {
          const companyGroup = await CompanyGroupService.findAllGroup()
          this.companyGroup = companyGroup.data
        }

        if (allRef?.companyDepartment) {
          this.companyDepartment = allRef?.companyDepartment
        } else {
          const companyDepartment = await CompanyDepartmentService.findAllCompanyDepartment()
          this.companyDepartment = companyDepartment.data
        }

        if (allRef?.companyCenter) {
          this.companyCenter = allRef?.companyCenter
        } else {
          const companyCenter = await CompanyCenterService.findAllCompanyCenter()
          this.companyCenter = companyCenter.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomerAndContact (ani) {
      try {
        const aniValue = this.$replacePhoneNumber(ani, '+66')
        const data = await CustomerService.customerProfileAndContact({
          ani: aniValue,
          customerName: this.customerName,
          contactName: this.contactName

        }, this.pagination?.page,
        this.pagination?.limit)
        if (Array.isArray(data?.data)) {
          this.customerProfileAndContact = data?.data
        }
        this.setPagination(data?.page, data?.totalDocs)
        this.$emit('update-count-customer-profile', data?.data?.length || 0)
      } catch (error) {
        console.error(error)
      }
    },
    searchCustomerAndContact (ani) {
      this.currentPhoneNumber = ani
      this.setPagination(1)
      this.getCustomerAndContact(ani)
    },
    selectAddContact (data) {
      if (data) {
        this.currentAddContact = data?.id
        this.isSidebarActive = !this.isSidebarActive
      }
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    async addCustomerContact (val) {
      try {
        if (this.currentAddContact) {
          const payload = { ...val }
          payload.customerId = this.currentAddContact
          payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
          payload.contactPhoneNo2 = this.$replacePhoneNumber(payload.contactPhoneNo2, '+66')
          await CustomerContactService.createGroup(payload)
          await this.searchCustomerAndContact(this.currentPhoneNumber)
          this.resetFormAndToggleSideBar(this.$refs.customerContactAdd)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Add Contact Successfully'
            }
          })
        }
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '[ERROR]: Add Contact Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Add Contact Failed'
          }
        })
      }
    },
    async getAllContactByCustomerId (customerId) {
      try {
        const { data } = await CustomerContactService.findAllByCustomerId(customerId)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    clearCustomerAndContact () {
      this.$emit('clear-customer-and-contact')
    },
    selectCustomerOnly (data) {
      const result = data
      this.$emit('select-customer-and-contact', result)
    },
    selectContactOnly (customerData, contactData) {
      const contactName = `${contactData?.firstName || ''} ${contactData?.lastName || ''}`
      const result = {
        ...customerData,
        contactName: contactName.trim(),
        contactPhoneNo: contactData?.contactPhoneNo,
        contactPhoneNo2: contactData?.contactPhoneNo2,
        ani: contactData?.contactPhoneNo || contactData?.contactPhoneNo2
      }
      this.$emit('select-customer-and-contact', result)
    },
    resetCustomerForm () {
      this.form = {
        customer: {
          customerId: '',
          name: '',
          ani: '',
          customerTypeId: '',
          customerGroupId: null,
          sapSoldToCode: '',
          sapShipToCode: '',
          refCode1: '',
          refCode2: '',
          refCode3: '',
          companyAddress: '',
          companySubDistrict: '',
          companyDistrict: '',
          companyProvince: '',
          companyZipCode: '',
          companyOfficePhone: '',
          companyMobilePhone: '',
          companyEmail: '',
          companyGroupId: null,
          companyDepartmentId: null,
          companyCenterId: null,
          remark1: '',
          remark2: '',
          remark3: '',
          queueAgent: 1
        },
        contact: {
          firstName: '',
          lastName: '',
          contactPhoneNo: '',
          contactEmail: '',
          contactLine: '',
          contactFacebook: ''
        }
      }
    },
    async rowProfileClick (row, index) {
      const isShowDetail = !row?._showDetails
      if (isShowDetail) {
        const contacts = await this.getAllContactByCustomerId(row?.id)
        this.customerProfileAndContact[index].customerContacts = contacts
      }
      this.$nextTick(() => {
        this.$set(row, '_showDetails', isShowDetail)
      })
    },
    onSubmit () {
      // console.log('Submit')
    },
    resetForm () {
      this.ani = ''
      this.customerName = ''
      this.customerId = null
      this.$emit('reset-form')
    },
    searchCustomer () {
      setTimeout(() => {
        if (this.ani.length === 10) {
          this.$emit('search-customer', this.ani)
        }
      }, 50)
    },
    searchCustomerId () {
      setTimeout(() => {
        this.$emit('search-customer-id', this.customerId)
      }, 100)
    },
    selectCustomer (event) {
      this.$emit('select-customer', event)
    },
    searchCustomerProfileByName () {
      setTimeout(() => {
        this.$emit('search-name-customer', this.customerName)
      }, 300)
    },
    filterCustomer (items, value) {
      const upperValue = value.toUpperCase()
      return items.filter((str) => (str && str.name ? str.name.toUpperCase().indexOf(upperValue) >= 0 : ''))
    },
    onSelectAddress (address) {
      this.form.customer.companySubDistrict = address?.subDistrict || ''
      this.form.customer.companyDistrict = address?.district || ''
      this.form.customer.companyProvince = address?.province || ''
      this.form.customer.companyZipCode = address?.postcode || ''
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCustomerAndContact()
    }
  }
}
</script>
