<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="knowledge-modal"
    v-model="modalShow"
    v-bind="$attrs"
    size="xl"
    centered
    title="Knowledge Base Detail"
    ok-only
    v-on="{...$listeners}"
  >
    <div class="mb-1">
      <span class="font-weight-bold">Code: </span>
      <span>{{ knowledgeBase.code }}</span>
    </div>
    <div
      v-if="knowledgeBase && knowledgeBase.knowledgeType && knowledgeBase.knowledgeType.title"
      class="mb-1">
      <span class="font-weight-bold">knowledge Type: </span>
      <span v-html="knowledgeBase.knowledgeType.title"></span>
    </div>
    <div class="mb-1">
      <span class="font-weight-bold">Subject: </span>
      <span>{{ knowledgeBase.subject }}</span>
    </div>
    <div class="mb-1">
      <span class="font-weight-bold">Problem Detail: </span>
      <span v-html="knowledgeBase.problemDetail"></span>
    </div>
    <div class="mb-1">
      <span class="font-weight-bold">Solution Detail: </span>
      <span v-html="knowledgeBase.solutionDetail"></span>
    </div>
    <!-- <div class="mb-1">
      <span class="font-weight-bold">Problem Files: </span>
      <span v-html="knowledgeBase.problemFile"></span>
    </div>
    <div class="mb-1">
      <span class="font-weight-bold">Solution File: </span>
      <span v-html="knowledgeBase.solutionFile"></span>
    </div> -->
    <template #modal-footer="{ hide }">
      <div>
        <b-button
          variant="outline-danger"
          style="margin-right: 12px"
          @click="hide"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="onSelect()">
          Select
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal
  },
  props: {
    knowledgeBase: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      knowledgeItemsValue: [],
      paginationValue: {
        page: 1,
        totalDocs: 1,
        limit: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: false },
        { key: 'subject',
          label: ' Subject',
          thStyle: {
            width: '20rem'
          } },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' }
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
      ],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      searchCodeAndSubject: ''
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    searchKnowledge: {
      get () {
        return this.search
      },
      set (val) {
        this.$emit('update:search', val)
      }
    }
  },
  mounted () {
    // this.getAllCategories()
  },
  methods: {
    onSelect () {
      this.$emit('on-select', this.knowledgeBase)
    }
  }
}
</script>
<style scoped>
span {
  font-size: 16px !important;
}
</style>
