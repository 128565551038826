<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <KnowledgeBaseDetail
      v-if="isKnowledgeDetailModal"
      v-model="isKnowledgeDetailModal"
      :knowledge-base="currentKnowledgeBase"
      @on-select="selectKnowledgebase($event)"
    />
    <b-modal
      id="knowledge-modal"
      v-model="modalShow"
      v-bind="$attrs"
      size="xl"
      centered
      title="Knowledge Based"
      ok-only
      v-on="{...$listeners}"
    >
      <b-card-text class="font-weight-bold">
        Category
      </b-card-text>
      <b-row class="align-items-end">
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlOne"
            name="Category level 1"
            form-label="Category level 1"
            placeholder="Category level 1"
            return-value="id"
            label-option="name"
            :options="lvlOneOptions"
            @input="selectedItem($event, 1)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlTwo"
            :disabled="!lvlTwoOptions || lvlTwoOptions.length <= 0 || isFormDisabled"
            name="Category level 2"
            form-label="Category level 2"
            placeholder="Category level 2"
            :options="lvlTwoOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 2)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlThree"
            :disabled="!lvlThreeOptions || lvlThreeOptions.length <= 0 || isFormDisabled"
            name="Category level 3"
            form-label="Category level 3"
            placeholder="Category level 3"
            :options="lvlThreeOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 3)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlFour"
            :disabled="!lvlFourOptions || lvlFourOptions.length <= 0 || isFormDisabled"
            name="Category level 4"
            form-label="Category level 4"
            placeholder="Category level 4"
            :options="lvlFourOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 4)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlFive"
            :disabled="!lvlFiveOptions || lvlFiveOptions.length <= 0 || isFormDisabled"
            name="Category level 5"
            form-label="Category level 5"
            placeholder="Category level 5"
            :options="lvlFiveOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 5)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlSix"
            :disabled="!lvlSixOptions || lvlSixOptions.length <= 0 || isFormDisabled"
            name="Category level 6"
            form-label="Category level 6"
            placeholder="Category level 6"
            :options="lvlSixOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 6)"
          />
        </b-col>
      </b-row>
      <div class="my-2">
        <SearchTextInput
          v-model="searchCodeAndSubject"
          label="Search Knowledge Based"
          placeholder="Search Knowledge Based"
          @keyup.enter.native="fetchSearch()" />
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="my-1"
          @click="fetchSearch()"
        >
          <feather-icon icon="SearchIcon" />Search
        </b-button>
      </div>
      <b-table
        :items="knowledgeItemsValue"
        :fields="fields"
        striped
        hover
        responsive
        @row-clicked="rowClick($event)">
        >
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value|| '-' }}</span>
        </template>
        <template #cell(subject)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(problemDetail)="data">
          <span
            class="font-weight-semibold"
            v-html="$wrapWords(data.value, 5)">
          </span>
        </template>
        <template #cell(solutionDetail)="data">
          <span
            class="font-weight-semibold"
            v-html="$wrapWords(data.value, 5)"></span>
        </template>
      </b-table>
      <b-pagination
        v-model="paginationValue.page"
        :total-rows="paginationValue.totalDocs"
        :per-page="paginationValue.limit"
        first-number
        last-number
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)">
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-modal>
  </div>
</template>
<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import SelectField from '@/components/Form/SelectField.vue'
import { BModal } from 'bootstrap-vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import KnowledgeBaseDetail from './KnowledgeBaseDetailModal.vue'

const CategoriesService = new CategoriesProvider()
const KnowledgeService = new KnowledgeProvider()

export default {
  components: {
    BModal,
    SearchTextInput,
    SelectField,
    KnowledgeBaseDetail
  },
  props: {
    knowledgeItems: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      required: true
    },
    search: {
      type: String,
      default: ''
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        totalDocs: 1,
        limit: 1
      })
    },
    ticket: {
      type: Object,
      default: () => ({})
    },
    lvlOptions: {
      type: Object,
      default: () => ({})
    },
    isFormDisabled: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      knowledgeItemsValue: [],
      isKnowledgeDetailModal: false,
      currentKnowledgeBase: {},
      paginationValue: {
        page: 1,
        totalDocs: 1,
        limit: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: false },
        { key: 'subject',
          label: 'Subject',
          thStyle: {
            width: '20rem'
          }
        },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' }
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
      ],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      searchCodeAndSubject: ''
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    searchKnowledge: {
      get () {
        return this.search
      },
      set (val) {
        this.$emit('update:search', val)
      }
    }
  },
  mounted () {
    // this.getAllCategories()
    this.initialData()
  },
  methods: {
    initialData () {
      this.categoryLevel.categorySearchLvlOne = this.ticket.categoryLv1
      this.categoryLevel.categorySearchLvlTwo = this.ticket.categoryLv2
      this.categoryLevel.categorySearchLvlThree = this.ticket.categoryLv3
      this.categoryLevel.categorySearchLvlFour = this.ticket.categoryLv4
      this.categoryLevel.categorySearchLvlFive = this.ticket.categoryLv5
      this.categoryLevel.categorySearchLvlSix = this.ticket.categoryLv6
      this.lvlOneOptions = this.lvlOptions.lvlOneOptions
      this.lvlTwoOptions = this.lvlOptions.lvlTwoOptions
      this.lvlThreeOptions = this.lvlOptions.lvlThreeOptions
      this.lvlFourOptions = this.lvlOptions.lvlFourOptions
      this.lvlFiveOptions = this.lvlOptions.lvlFiveOptions
      this.lvlSixOptions = this.lvlOptions.lvlSixOptions
      this.knowledgeItemsValue = this.knowledgeItems
      this.paginationValue.page = this.pagination?.page || 1
      this.paginationValue.totalDocs = this.pagination.totalDocs || 10
      this.paginationValue.limit = this.pagination.limit || 10
    },
    async getAllCategories () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findAll()
        if (data) {
          this.categories = data
        }
        this.lvlOneOptions = data.filter((item) => item.level === 1)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getAllKnowledgeByCategory () {
      try {
        const categoryId = this.getLastItem(this.categoryLevel)
        const payload = {
          searchCodeAndSubject: this.searchCodeAndSubject,
          categoriesIds: [categoryId]
        }
        if (!categoryId) {
          delete payload.categoriesIds
        }
        const data = await KnowledgeService.paginate(this.paginationValue.page, this.paginationValue.limit, payload)
        if (data) {
          this.setPaginationKnowledge(data.page, data.totalDocs)
          this.knowledgeItemsValue = data.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    setPaginationKnowledge (page, totalDocs) {
      this.paginationValue.page = page || this.paginationValue.page
      this.paginationValue.totalDocs = totalDocs || this.paginationValue.totalDocs
    },
    showModal () {
      this.$bvModal.show('knowledge-modal')
    },
    rowClick (val) {
      this.currentKnowledgeBase = val
      this.isKnowledgeDetailModal = true
      // const payload = {
      //   problemDetail: val.problemDetail || '',
      //   solutionDetail: val.solutionDetail || ''
      // }
      // this.$emit('selectd-row', payload)
    },
    selectKnowledgebase (val) {
      this.isKnowledgeDetailModal = false
      const payload = {
        problemDetail: val.problemDetail || '',
        solutionDetail: val.solutionDetail || ''
      }
      this.$emit('selectd-row', payload)
    },
    showCurrentPage (page) {
      this.$emit('show-current-page', page)
    },
    fetchSearch () {
      this.setPaginationKnowledge(1)
      this.getAllKnowledgeByCategory()
    },
    // fetchSearch () {
    //   this.$emit('fetch-search')
    // },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlTwoOptions = filter
        this.lvlThreeOptions = []
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlTwo = null
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 2:
        this.lvlThreeOptions = filter
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 3:
        this.lvlFourOptions = filter
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 4:
        this.lvlFiveOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 5:
        this.lvlSixOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlSix = null
        break
      default:
        break
      }
    },
    findCategory (parentId) {
      return this.categories.filter((item) => item.parentId === parentId)
    },
    getLastItem (obj) {
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    }
  }
}
</script>
