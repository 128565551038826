import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class TicketTypeProvider extends HttpRequest {
  findAll () {
    return this.get('/ticket-type')
  }

  findAllType () {
    return this.get('/ticket-type/all/type')
  }

  createGroup (payload) {
    return this.post('/ticket-type', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/ticket-type/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/ticket-type/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/ticket-type?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default TicketTypeProvider
