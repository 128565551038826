<template>
  <div class="m-1">
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
        <label>Show</label>
        <v-select
          v-model="innerValue"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50" />
        <label>entries</label>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Add'
    },
    value: {
      type: Number,
      default: 10
    },
    toPath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      perPageOptions: [10, 20, 50, 100]
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    emitActiveSideBar () {
      this.$emit('active-sidebar')
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
