function resizeBase64ImagesInHTML (htmlString) {
  // Create a temporary div element to hold the parsed HTML
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString

  // Select all image elements
  const images = tempDiv.getElementsByTagName('img')

  // Loop through each image
  for (let i = 0; i < images.length; i++) {
    const img = images[i]
    if (img.src.startsWith('data:image')) {
      // Check if the image source is a base64 data URL

      // Create a new image element for resizing
      const newImg = new Image()
      newImg.src = img.src

      // Check the image's natural width
      if (newImg.naturalWidth > 1000) {
        // If the image is wider than 1000px, resize it to 500px
        newImg.width = 500
        newImg.height = (500 * newImg.naturalHeight) / newImg.naturalWidth
      }

      // Replace the original image with the resized one
      img.parentNode.replaceChild(newImg, img)
    }
  }

  // Return the HTML with resized images
  return tempDiv.innerHTML
}

export default resizeBase64ImagesInHTML
