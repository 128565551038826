<template>
  <b-modal
    id="modal-danger"
    ref="confirm"
    v-model="isVisible"
    v-bind="$attrs"
    :ok-variant="variant"
    ok-title="Accept"
    modal-class="modal-normal"
    centered
    v-on="{ ...$listeners }">
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        Transfer Ticket
      </p>
    </div>
    <div
      class="mx-auto mb-2"
    >
      <AutoCompleteField
        v-model="userName"
        name="Progress By"
        label="Agent Name"
        placeholder="Search Agent By Name"
        item-value="fullName"
        :filter-items="filterUser"
        :items="userItems"
        @keydown="searchUserByName()"
        @select="selectUser($event)"
      >
        <template #item-text="{ item }">
          {{ item.fullName }}
        </template>
      </AutoCompleteField>
    </div>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          :variant="variant"
          style="margin-right: 12px"
          @click="_confirm()">
          Save
        </b-button>
        <!-- <b-button
          v-if="ticketCodeReferValue"
          variant="danger"
          style="margin-right: 12px"
          @click="remove()">
          Delete
        </b-button> -->
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import UserProvider from '@/resources/UserProvider'
import AutoCompleteField from '@/components/Form/AutoCompleteField.vue'

const UserService = new UserProvider()

export default {
  components: {
    AutoCompleteField
  },
  props: {
    ticketCodeRefer: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      title: 'Are you sure?',
      description: '',
      variant: 'danger',
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null,
      userItems: [],
      transferId: null,
      userName: ''
    }
  },
  computed: {
    ticketCodeReferValue: {
      get () {
        return this.ticketCodeRefer
      },

      set (val) {
        this.$emit('update-ticket-code-refer', val)
      }
    }
  },
  methods: {
    async searchUserByName () {
      try {
        setTimeout(async () => {
          const { data } = await UserService.paginate(1, 10, { searchFullName: this.userName })
          const result = []
          data.forEach((item) => {
            result.push({ ...item, fullName: `${item?.firstName} ${item?.lastName} (${item?.userGroup?.name})` })
          })
          this.userItems = result
        }, 100)
      } catch (error) {
        console.error(error)
      }
    },
    selectUser (val) {
      this.transferId = +val.id
    },
    filterUser (items, value) {
      const upperValue = value.toUpperCase()
      return items.filter((str) => (str && str.fullName ? str.fullName.toUpperCase().indexOf(upperValue) >= 0 : ''))
    },
    show ({ title, description, variant }) {
      this.title = title || 'Are you sure?'
      this.description = description
      this.variant = variant || 'danger'
      this.isVisible = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm () {
      this.isVisible = false
      this.resolvePromise(this.transferId)
    },

    _cancel () {
      this.isVisible = false
      this.resolvePromise(false)
    },

    remove () {
      this.isVisible = false
      this.ticketCodeReferValue = null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
