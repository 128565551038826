<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="knowledge-modal"
    v-model="modalShow"
    v-bind="$attrs"
    size="xl"
    modal-class="modal-normal"
    centered
    title="Send Email"
    ok-title="Send"
    v-on="{...$listeners}"
  >
    <div class="d-flex mb-2">
      <b-button
        class="mr-2"
        :variant="typeEmail === 'favorite' ? 'primary' : ''"
        @click="changeType('favorite')"
      >
        Favorite
      </b-button>
      <b-button
        :variant="typeEmail === 'group' ? 'primary' : ''"
        @click="changeType('group')">
        Group
      </b-button>
    </div>
    <b-row>
      <b-col
        v-show="typeEmail === 'favorite'"
        cols="12"
        md="6"
      >
        <div class="mb-2">
          Favorites Email
        </div>
        <div class="mb-2">
          <SearchTextInput
            v-model="search"
            label="Search Favriote Email"
            placeholder="Search Favriote Email "
            @keyup.enter.native="fetchFilter()" />
        </div>
        <div style="max-width: 100%">
          <b-table
            :items="emailItems"
            :fields="fields"
            striped
            hover
            responsive
            @row-clicked="rowClick($event)">
            >
            <template #cell(to)="data">
              <b-row>
                <b-col cols="6">
                  <b-button
                    :variant="checkEmailActive(data.item.email, 'to') ? 'danger' : 'success'"
                    @click="actionToClick(data.item)">
                    {{ checkEmailActive(data.item.email, 'to') ? 'Cancel' : 'To' }}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    :variant="checkEmailActive(data.item.email, 'cc') ? 'danger' : 'warning'"
                    @click="actionCcClick(data.item)"
                  >
                    {{ checkEmailActive(data.item.email, 'cc') ? 'Cancel' : 'CC' }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </div>
        <div class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)"
          >
          </b-pagination>
        </div>
      </b-col>
      <b-col
        v-show="typeEmail === 'group'"
        cols="12"
        md="6"
      >
        <div class="mb-2">
          Group Email
        </div>
        <div class="mb-2">
          <SearchTextInput
            v-model="searchGroupName"
            label="Search Group Name"
            placeholder="Search Group Name "
            @keyup.enter.native="fetchFilterGroupEmail()" />
        </div>
        <!-- <div class="mb-2">
          <SearchTextInput
            v-model="search"
            label="Search Favriote Email"
            placeholder="Search Favriote Email "
            @keyup.enter.native="fetchFilter()" />
        </div> -->
        <div style="max-width: 100%">
          <b-table
            :items="emailGroup"
            :fields="emailGroupfields"
            striped
            hover
            responsive
            @row-clicked="rowClick($event)">
            >
            <template #cell(action)="data">
              <b-row>
                <b-col cols="6">
                  <b-button
                    :variant="checkEmailActive(data.item.email, 'to') ? 'danger' : 'success'"
                    @click="selectEmailGroup('to', data.item)">
                    {{ checkEmailActive(data.item.email, 'to') ? 'Cancel' : 'To' }}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    :variant="checkEmailActive(data.item.email, 'cc') ? 'danger' : 'warning'"
                    @click="selectEmailGroup('cc', data.item)"
                  >
                    {{ checkEmailActive(data.item.email, 'cc') ? 'Cancel' : 'CC' }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </div>
        <div class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="paginationGroupEmail.page"
            :total-rows="paginationGroupEmail.totalDocs"
            :per-page="paginationGroupEmail.limit"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPageGroupEmail($event)"
          >
          </b-pagination>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <label>
                  To:
                  <span
                    class="label-required">
                    *
                  </span>
                </label>
              </template>
              <v-select
                v-model="to"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                taggable
                no-drop
                class="flex-grow-1 email-to-selector select-full-style"
                :close-on-select="false"
                :options="emailOptions"
                input-id="email-to"
                @input="parseEmails('to', $event)"
              >
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <label>
                  CC:
                </label>
              </template>
              <v-select
                v-model="cc"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                taggable
                no-drop
                class="flex-grow-1 email-to-selector select-full-style"
                :close-on-select="false"
                :options="emailOptions"
                input-id="email-to"
                @input="parseEmails('cc', $event)"
              >
                <template v-slot:no-options="{ }">
                </template>
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <TextFieldInput
              v-model="subject"
              label="Subject:"
              name="Subject"
            />
          </b-col>
          <b-col
            class="mb-2"
            cols="12"
          >
            <b-form-group>
              <template #label>
                <label>
                  Message:
                  <span
                    class="label-required">
                    *
                  </span>
                </label>
                <div
                  class="my-2">
                  <PreviewSwitch
                    v-model="isPreviewMessage"
                  >
                    Preview
                  </PreviewSwitch>
                </div>
                <div
                  v-if="isPreviewMessage"
                  class="box-preview"
                  v-html="message"
                >
                </div>
                <wysiwyg
                  v-else
                  v-model="message"
                />
              </template>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer="{ hide }">
      <div>
        <b-button
          variant="outline-primary"
          style="margin-right: 12px"
          @click="hide">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="onSend()">
          Send
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FavoriteEmailProvider from '@/resources/FavoriteEmailProvider'
import CustomerProvider from '@/resources/CustomerProvider'
import EmailGroupProvider from '@/resources/EmailGroupProvider'
import PreviewSwitch from '../PreviewSwitch.vue'

const FavoriteEmailService = new FavoriteEmailProvider()
const CustomerService = new CustomerProvider()
const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    BModal,
    TextFieldInput,
    SearchTextInput,
    PreviewSwitch
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    initSubject: {
      type: String,
      default: ''
    },
    initMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: true,
      typeEmail: 'favorite',
      isPreviewMessage: false,
      searchGroupName: '',
      subject: '',
      message: '',
      to: [],
      cc: [],
      search: '',
      pagination: {
        page: 1,
        totalDocs: 1,
        limit: 5
      },
      paginationGroupEmail: {
        page: 1,
        totalDocs: 1,
        limit: 5
      },
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'to', label: '' },
        { key: 'cc', label: '' }
      ],
      emailGroupfields: [
        { key: 'name', label: 'Name' },
        { key: 'action', label: '' }
      ],
      emailItems: [],
      emailToOptions: [],
      editorOption: {
        modules: {
          toolbar: true
        },
        theme: 'snow',
        placeholder: 'Message'
      },
      emailGroup: [],
      searchNameGroup: ''
    }
  },
  computed: {
    emailOptions () {
      return [...new Set(this.emailToOptions)]
    },
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    // subject: {
    //   get () {
    //     return this.initSubject
    //   },
    //   set (val) {
    //     this.subject = val
    //   }
    // },
    // message: {
    //   get () {
    //     return this.initMessage
    //   },
    //   set (val) {
    //     this.message = val
    //   }
    // },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  mounted () {
    this.subject = this.initSubject
    this.message = this.initMessage
    this.getAllFavoriteEmailByUserId(this.profile.id)
    this.getAllEmailGroup()
  },
  methods: {
    onSend () {
      this.$emit('send-email', {
        to: this.to,
        cc: this.cc,
        subject: this.subject,
        message: this.message
      })
    },
    async getAllFavoriteEmailByUserId () {
      try {
        const data = await FavoriteEmailService.findByUserId(this.profile.id, this.pagination.page, this.pagination.limit, { search: this.search })
        this.setPagination(data.page, data.totalDocs)
        this.emailItems = data.data

        if (data.data && data.data.length > 0) {
          const emailItems = data.data.map((item) => item.email)
          this.setEmailOptions(emailItems)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllCustomerByEmail (text) {
      try {
        const data = await CustomerService.paginate(1, 5, { searchEmail: text })
        if (data.data && data.data.length > 0) {
          const emailItems = data.data.map((item) => item.companyEmail)
          if (emailItems) {
            this.setEmailOptions(emailItems)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllEmailGroup () {
      try {
        const data = await EmailGroupService.findAll({
          ...this.paginationGroupEmail,
          search: this.searchGroupName
        })
        this.setPaginationGroupEmail(data?.page, data?.totalDocs)
        if (data?.data && data?.data.length > 0) {
          this.emailGroup = data?.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async selectEmailGroup (type, item) {
      if (item) {
        const emailCenter = await this.getEmailGroupById(item?.id)
        if (emailCenter) {
          if (type === 'to') {
            this.to = emailCenter
          }
          if (type === 'cc') {
            this.cc = emailCenter
          }
        }
      }
    },
    async getEmailGroupById (id) {
      try {
        const { data } = await EmailGroupService.findOne(id)
        const emailCenter = data?.emailCenter
        if (Array.isArray(emailCenter) && emailCenter.length > 0) {
          return emailCenter.map((item) => item?.email)
        }
      } catch (error) {
        console.error(error)
      }
      return null
    },
    // searchCustomerEmail (event) {
    //   setTimeout(() => {
    //     this.getAllCustomerByEmail(event.target.value)
    //   }, 1000)
    // },
    setEmailOptions (items) {
      if (items && items.length > 0 && !this.emailToOptions.includes(items)) {
        this.emailToOptions.push(...items)
        this.emailToOptions = [...new Set(this.emailToOptions)]
        this.emailToOptions = this.emailToOptions.filter((item) => item)
      }
    },
    rowClick ({ email }) {
      if (email && !this.to.includes(email)) {
        this.to.push(email)
      }
    },
    actionToClick ({ email }) {
      if (email && !this.to.includes(email)) {
        this.to.push(email)
      } else {
        const index = this.to.findIndex((item) => item === email)
        this.to.splice(index, 1)
      }
    },
    actionCcClick ({ email }) {
      if (email && !this.cc.includes(email)) {
        this.cc.push(email)
      } else {
        const index = this.cc.findIndex((item) => item === email)
        this.cc.splice(index, 1)
      }
    },
    checkEmailActive (email, type) {
      if (type === 'to') {
        return this.to.includes(email)
      }
      if (type === 'cc') {
        return this.cc.includes(email)
      }
      return false
    },
    convertFormatEmail (email) {
      if (email && typeof (email) === 'string') {
        email.split(';')
      }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getAllFavoriteEmailByUserId()
    },
    fetchFilterGroupEmail () {
      this.setPaginationGroupEmail(1)
      this.getAllEmailGroup()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getAllFavoriteEmailByUserId()
    },
    showCurrentPageGroupEmail (page) {
      this.setPaginationGroupEmail(page)
      this.getAllEmailGroup()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    setPaginationGroupEmail (page, totalDocs) {
      this.paginationGroupEmail.page = page || this.paginationGroupEmail.page
      this.paginationGroupEmail.totalDocs = totalDocs || this.paginationGroupEmail.totalDocs
    },
    parseEmails (type, data) {
      const { length } = data
      const email = data[length - 1]
      const emailArray = email.split(/[;,]+/)
      if (type === 'to') {
        const { to } = this

        if (Array.isArray(data) && Array.isArray(to) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            to.pop()
            to.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.to) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.to = [...new Set(newEmail)]
      }
      if (type === 'cc') {
        const { cc } = this
        if (Array.isArray(data) && Array.isArray(cc) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            cc.pop()
            cc.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.cc) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.cc = [...new Set(newEmail)]
      }
    },

    isValidEmail (email) {
      // Regular expression pattern to validate email address
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      // Return true if email matches pattern, false otherwise
      return emailPattern.test(email)
    },
    changeType (type) {
      this.typeEmail = type
    }
  }
}
</script>
<style>
.customize {
  min-height: 600px;
}
</style>
